.internal-template-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .internal-template-container {
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-template-rows: auto 1fr;
  }

    .internal-template-nav-menu {
        flex: 1;
        background-color: white;
        margin-top: 16px;
        margin-left: 16px;
    }
}

.internal-template-nav-menu {
    background-color: white;
    margin-top: 16px;
    margin-left: 16px;
}

.internal-template-nav-menu .input-group {
    flex-wrap: initial;
}

.internal-template-header {
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.internal-template-header-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 32px;
}

.internal-template-footer {
    grid-column: span 3;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
}

.internal-template-main-component {
    flex: 1;
    padding-top: 16px !important;
    padding: 42px;
}

.card {
    min-width: auto;
    margin: 16px;
}

.active-button-icon {
    margin-right: 5px;
    font-size: 30px;
    color: white;
}

@media only screen and (max-width: 768px) {
    .internal-template-main-component {
        padding: 0px;
    }
    
    .active-button-text {
        font-size: 8px;
    }

    .ant-table-cell {
        font-size: 10px;
    }

    .active-button-icon {
        font-size: 20px;
    }

    .internal-template-header-row {
        padding: 16px;
    }
    
    .internal-template-footer {
        font-size: 10px;
    }
    
    .internal-template-nav-menu {
        margin-right: 16px;
    }
}
