.header__logo {
  max-width: 100px;
  max-height: 55px;
}

.header__logos {
  max-width: 80px;
}

@media only screen and (max-width: 768px) {
    .header__logo {
    max-width: 60px;
    max-height: 50px;
    }
}
