body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.personalized-bar-blue,
.personalized-bar-soft-blue,
.personalized-bar-pink,
.personalized-bar-soft-pink,
.personalized-bar-soft-green,
.personalized-bar-green,
.personalized-bar-gray,
.personalized-bar-orange {
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  font-size: 1rem;
  background-color: #e9ecef;
  border-radius: .45rem;
  font-weight: bold;
}

.personalized-bar-soft-blue-bar {
  background-color: #70A1D1;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-blue-bar {
  background-color: #1a4f7b;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-pink-bar {
  background-color: #B76D93;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-soft-pink-bar {
  background-color: #C77888;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-green-bar {
  background-color: #9AC282;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-soft-green-bar {
  background-color: #C4D9C2;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-gray-bar {
  background-color: #C8D2D8;
  text-align: center;
  color: blanchedalmond;
}

.personalized-bar-orange-bar {
  background-color: #DAA271;
  text-align: center;
  color: blanchedalmond;
}

.ant-steps-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.font-semibold {
  font-size: 14px;
}

h3.font-semibold {
  margin: 0;
}

.form-check .form-check-input {
  border: 1px solid #adb5bd;
}

.ant-select-dropdown {
  z-index: 10000;
}

.w800 {
  width: 800px !important;
}