#login {
    height: 100vh;
    padding: 0;
}

#login .language-selector {
    position: absolute;
    top: 50px;
    right: 50px;
}

.login__col-left {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 100px;
}

.login__col-left .p-5 {
    order: 1;
    padding: 80px !important;
    padding-right: 0 !important;
}

.login__col-right {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__logo {
    margin-bottom: 40px;
}

.login__logo__upper {
    display: none;
}

.login__logo img {
    max-width: 300px;
}

.form-signin {
    width: 400px;
    padding-bottom: 0;
}

@media only screen and (max-width: 600px) {
    #login {
        height: auto;
    }

    #login .language-selector {
        top: 24px;
        right: 4px;
        z-index: 2;
    }

    .login__col-left {
        padding-top: 80px;
        padding-right: 20px;
        padding-bottom: 20px;
        overflow: hidden;
        z-index: 1;
    }

    .login__logo {
        display: none;
    }

    .login__logo__upper {
        position: absolute;
        display: block;
        top: 8px;
        left: 4px;
        z-index: 2;
        max-width: 80px;
    }

    .login__col-right {
        margin-top: 30px;
    }

    .login__col-left .p-5 {
        padding: 30px 0px 10px 30px !important;
    }

    .form-signin {
        min-width: auto;
        width: 85%;
    }
}
