.question-header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(112,161,209);
    font-weight: bold;
    text-align: center;
    min-width: 75px;
}

.check-column-div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 75px;
}

.check-column-mobile-div {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    margin-bottom: 12px;
}

.response-label {
    font-size: 12px;
    color: rgb(112,161,209);
    align-self: flex-start;
}

@media only screen and (max-width: 768px) {
    .question-header-row {
        min-width: auto;
        font-size: 10px;
    }

    .question-label {
        font-size: 12px;
    }

    .check-column-div {
        min-width: auto;
    }
}
