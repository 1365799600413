@media only screen and (max-width: 768px) {
    .ant-table-thead > tr > th {
        padding: 8px 8px;
    }

    .ant-table-thead > tr > th {
        padding: 8px 8px;
    }

    .ant-pagination {
        justify-content: center;
        font-size: 12px;  /* default = 14px */
    }

    .form-label {
        font-size: 12px;  /* default = 14px */
    }

    .card {
        margin: 8px !important;  /* default = 16px */
    }

    .modal-body {
        font-size: 12px;  /* default = 14px */
    }

    .btn {
        font-size: 12px;  /* default = 14px */
    }

    .h1, h1 {
        font-size: 26px;  /* default = 32px */
    }

    .h2, h2 {
        font-size: 20px;  /* default = 24px */
    }

    .h3, h3 {
        font-size: 15px;  /* default = 18.72px */
    }

    .h4, h4 {
        font-size: 14px;  /* default = 16px */
    }

    .h5, h5 {
        font-size: 10.6px;  /* default = 13.28px */
    }

    .h6, h6 {
        font-size: 9px;  /* default = 10.72px */
    }
}
