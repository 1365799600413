.report-texts {
    padding: 20px 20px 0px 20px;
}

.profile-radar-chart-container {
    width: 50%;
}

.profile-chart-container {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

@media only screen and (max-width: 600px) {
    .report-logo {
        max-width: 80%;
    }

    .report-h2 {
        font-size: 20px;
    }

    .report-h3 {
        font-size: 16px;
    }

    .report-h4 {
        font-size: 14px;
    }

    .report-texts {
        padding: 12px 0px 0px 0px;
        font-size: 12px;
    }

    .profile-radar-chart-container {
        width: 100%;
    }
}
